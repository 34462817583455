import React, { useState, useEffect } from "react";
import { useSub } from "contexts";
import { styled } from "@mui/material/styles";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { Box, LinearProgress, Typography } from "@mui/material";
import openaiSvg from "images/openai-svgrepo-com.svg";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
    ...theme.applyStyles("dark", {
      backgroundColor: "#308fe8",
    }),
  },
}));

const MyCurrentMonthUsage = () => {
  const { currentMonthUsage, planDetails } = useSub();
  const [quizLimit, setQuizLimit] = useState(0);
  const [questionLimit, setQuestionLimit] = useState(0);
  const [quizzesUsed, setQuizzesUsed] = useState(0);
  const [questionsUsed, setQuestionsUsed] = useState(0);
  const [quizUsagePercentage, setQuizUsagePercentage] = useState(0);
  const [questionUsagePercentage, setQuestionUsagePercentage] = useState(0);
  const currentMonthName = new Date().toLocaleString("default", { month: "long" });
  const nextMonthName = new Date(new Date().setMonth(new Date().getMonth() + 1)).toLocaleString("default", {
    month: "long",
  });

  useEffect(() => {
    if (planDetails) {
      setQuizLimit(planDetails.monthly_limits.quizzes);
      setQuestionLimit(planDetails.monthly_limits.questions);
    }
    if (currentMonthUsage) {
      setQuizzesUsed(currentMonthUsage.quizzes);
      setQuestionsUsed(currentMonthUsage.questions);
    }
  }, [planDetails, currentMonthUsage]);

  useEffect(() => {
    // in case someone generated more quizzes/questions than the limit for plan A while on plan B
    // but then canceled plan B. e.g, i had Voyager, generated 6 questions,
    // downgraded to explorer, and I see 6/2 question used
    setQuizUsagePercentage(Math.min((quizzesUsed / quizLimit) * 100, 100));
    setQuestionUsagePercentage(Math.min((questionsUsed / questionLimit) * 100, 100));
  }, [quizzesUsed, questionsUsed, quizLimit, questionLimit]);
  if (!currentMonthUsage || !planDetails) {
    return;
  }
  return (
    <Box>
      <h2>Usage for {currentMonthName}</h2>
      <p>Usage will reset on the first of {nextMonthName}.</p>
      <Box>
        <h3>
          Quizzes Generated: {quizzesUsed} / {quizLimit}
        </h3>
        <BorderLinearProgress variant="determinate" value={quizUsagePercentage} />
        <h3>
          Questions Generated: {questionsUsed} / {questionLimit}
        </h3>
        <BorderLinearProgress variant="determinate" value={questionUsagePercentage} />

        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginTop={2}>
          <h3>OpenAI Usage</h3>
          <img src={openaiSvg} alt="OpenAI" width="25" />
        </Box>

        <Typography variant={"body1"} marginTop={0}>
          <strong>${Math.round(currentMonthUsage.openai_usage.total_cost * 100) / 100}</strong> /{" "}
          <strong>{currentMonthUsage.openai_usage.prompt_tokens}</strong> total input tokens /{" "}
          <strong>{currentMonthUsage.openai_usage.completion_tokens}</strong> total output tokens
        </Typography>
      </Box>
    </Box>
  );
};

export default MyCurrentMonthUsage;
