import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { useDarkMode } from "components/Page";
import getTheme from "theme";
import { Main } from "layouts";
import {
  Home,
  MaintenanceMode,
  Login,
  Logout,
  SignUp,
  ErrorBoundary,
  ActivateAccount,
  Checkout,
  ContactPage,
  General as AccountGeneral,
  Billing as AccountBilling,
  Notifications as AccountNotifications,
  Security as AccountSecurity,
  PlanAndUsage as AccountPlanAndUsage,
  QuizPage,
  SharedQuizPage,
  RocketDash,
  CheckoutReturn,
  Plans,
} from "pages";
import { NavigationProvider, AuthProvider, ReCAPTCHAProvider, SubscriptionProvider } from "contexts";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute";
import { AboutStatement } from "pages/Home/components";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://8e830f61f27ce21644f972f593eb8fb5@o4507575791845376.ingest.us.sentry.io/4507575795515392",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const RootElement = () => {
  const [themeMode, themeToggler] = useDarkMode();
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    let maintenanceMode = process.env.REACT_APP_API_MAINTENANCE_MODE;
    maintenanceMode = maintenanceMode === "true";
    setMaintenanceMode(maintenanceMode);
  }, []);

  useEffect(() => {
    if (maintenanceMode) {
      setRoutes([
        {
          path: "*",
          Component: <MaintenanceMode />,
        },
      ]);
    } else {
      setRoutes([
        {
          path: "/",
          Component: <Home />,
          Layout: Main,
        },
        {
          path: "/login",
          Component: <Login />,
          Layout: Main,
        },
        {
          path: "/logout",
          Component: <Logout />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/activate_account/:userId/:token",
          Component: <ActivateAccount />,
          Layout: Main,
        },
        {
          path: "/signup",
          Component: <SignUp />,
          Layout: Main,
        },
        {
          path: "/about",
          Component: <AboutStatement />,
          Layout: Main,
        },
        {
          path: "/contact",
          Component: <ContactPage />,
          Layout: Main,
        },

        /* payment routes */
        {
          path: "/subscribe/:plan",
          Component: <Checkout />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/checkout-return/:sessionId/:plan",
          Component: <CheckoutReturn />,
          Layout: Main,
          NeedsLogin: true,
        },
        /* account routes */
        {
          path: "/account-general",
          Component: <AccountGeneral />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/account-billing",
          Component: <AccountBilling />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/account-notifications",
          Component: <AccountNotifications />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/account-security",
          Component: <AccountSecurity />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/account-plan",
          Component: <AccountPlanAndUsage />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/account",
          Component: <AccountGeneral />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/quiz/:quizId",
          Component: <QuizPage />,
          Layout: Main,
          NeedsLogin: true,
        },
        {
          path: "/sharedquiz/:quizId/:sharedLinkId",
          Component: <SharedQuizPage />,
          Layout: Main,
          NeedsLogin: false,
        },
        {
          path: "/rocketdash",
          Component: <RocketDash />,
          Layout: Main,
          NeedsLogin: true,
        },
        { path: "/plans", Component: <Plans />, Layout: Main, NeedsLogin: false },
      ]);
    }
  }, [maintenanceMode]);

  return (
    <ErrorBoundary
      onRetry={() => {
        window.location.reload();
      }}
    >
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <ReCAPTCHAProvider>
          <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
            <CssBaseline />
            <Paper elevation={0}></Paper>
            <BrowserRouter>
              <AuthProvider>
                <SubscriptionProvider>
                  <ToastContainer />
                  <NavigationProvider>
                    <Routes>
                      {routes.map(({ path, Component, Layout = ({ children }) => <>
                            {children}
                          </>, NeedsLogin = false }) => {
                        return (
                          <Route
                            key={path}
                            path={path}
                            element={
                              <Layout>{NeedsLogin ? <ProtectedRoute>{Component}</ProtectedRoute> : Component}</Layout>
                            }
                          />
                        );
                      })}
                    </Routes>
                  </NavigationProvider>
                </SubscriptionProvider>
              </AuthProvider>
            </BrowserRouter>
          </ThemeProvider>
        </ReCAPTCHAProvider>
      </GoogleReCaptchaProvider>
    </ErrorBoundary>
  );
};

root.render(<RootElement />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
