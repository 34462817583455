import React, { useRef, useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { getQuizzes } from "api/quiz";
import { QuizShareModal } from "components/QuizShareModal";
import QuizAccordion from "./QuizAccordion/QuizAccordion";

const QuizList = ({ quizzesLoading, quizzes, setQuizzes }) => {
  const pollingRef = useRef();
  const [showShareModalForQuiz, setShowShareModalForQuiz] = useState(null);
  const [editingQuizId, setEditingQuizId] = useState(null);
  const [deletingQuiz, setDeletingQuiz] = useState(false);

  useEffect(() => {
    let pollQuizzes = async () => {
      let quizzes = await getQuizzes();
      setQuizzes(quizzes);
    };
    // quizzes state has changed; check if any quizzes are pending
    const quizzesPending = quizzes.some((quiz) => quiz.status === "pending");
    // if true, wait for 3 seconds before polling again and updating state
    if (quizzesPending) {
      pollingRef.current = setTimeout(pollQuizzes, 3000);
    } else {
      // if no quizzes are pending, clear the interval
      clearInterval(pollingRef.current);
    }
  }, [quizzes]);

  if (showShareModalForQuiz !== null) {
    return <QuizShareModal onClose={() => setShowShareModalForQuiz(null)} quiz={showShareModalForQuiz} />;
  }

  // render accordions (full width) with quiz data
  if (!quizzesLoading && quizzes.length === 0) {
    return (
      <Box width={1} display={"flex"} alignItems={"top"} justifyContent={"center"} flexDirection={"column"}>
        <Typography variant="h4" gutterBottom color="primary" textAlign={"center"}>
          my quizzes
        </Typography>
        <Typography variant="h6" gutterBottom color="primary" textAlign={"center"}>
          You have no quizzes. Use the form above to generate a quiz.
        </Typography>
      </Box>
    );
  } else if (quizzesLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"200px"}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={1} display={"flex"} alignItems={"top"} justifyContent={"center"} flexDirection={"column"}>
      <Typography variant="h4" gutterBottom color="primary" textAlign={"center"}>
        my quizzes
      </Typography>
      {quizzes.map((quiz) =>
        quiz.question_generation_logs.map((qgl) => (
          <QuizAccordion
            setShowShareModalForQuiz={setShowShareModalForQuiz}
            key={qgl.id}
            quiz={quiz}
            qgl={qgl}
            setQuizzes={setQuizzes}
            setEditingQuizId={setEditingQuizId}
            setDeletingQuiz={setDeletingQuiz}
            deletingQuiz={deletingQuiz}
            editingQuizId={editingQuizId}
          />
        )),
      )}
    </Box>
  );
};

export default QuizList;
