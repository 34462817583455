import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { Modal as ModalMUI } from "@mui/material";
import { Share } from "@mui/icons-material";
import { createQuizShareLink, listQuizShareLinks } from "api/quiz";
import { notifySuccess } from "toastNotifications";
import { QuizShareLinksList } from "./components";

const QuizShareModal = ({ onClose = () => {}, quiz = null }) => {
  const [quizShareLinks, setQuizShareLinks] = useState([]);

  const updateLinks = ({ quizId }) => {
    listQuizShareLinks({ quizId }).then((response) => {
      setQuizShareLinks(response.share_links);
      console.log("response", response);
    });
  };
  useEffect(() => {
    if (quiz) {
      updateLinks({ quizId: quiz.id });
    }
  }, []);

  const isMobile = window.innerWidth < 600;

  return (
    <ModalMUI
      open={true}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "100%" : "50%",
          bgcolor: "white",
          border: "1px solid #ccc",
          boxShadow: 3,
          height: isMobile ? "100%" : "auto",
          borderRadius: "10px",
          p: 3,
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} marginBottom={2} gap={2}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            Share Quiz
          </Typography>
          <Share />
        </Box>

        <Divider />
        <Typography id="modal-modal-description" sx={{ mt: 2, textAlign: "center", marginBottom: 2 }}>
          Create a public link to share this quiz with friends. All links you create will be listed below.
        </Typography>
        <QuizShareLinksList updateLinks={updateLinks} quiz={quiz} links={quizShareLinks} />

        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
          <Button variant="outlined" onClick={onClose} sx={{ flex: 1 }}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              createQuizShareLink({ quizId: quiz.id })
                .then(() => {
                  notifySuccess("Share link created");
                  updateLinks({ quizId: quiz.id });
                })
                .catch((error) => {
                  console.error("Error creating quiz share link", error);
                });
            }}
            sx={{ flex: 1 }}
          >
            Create Share Link
          </Button>
        </Box>
      </Box>
    </ModalMUI>
  );
};
export default QuizShareModal;
