import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNav } from "contexts";
import { LoadingScreen } from "components/Loading";
import { getSharedQuizWithLink } from "api/quiz";
import { Container } from "components";
import { Box, Button, Card, Typography } from "@mui/material";
import { Quiz } from "components";

const SharedQuizPage = () => {
  const { quizId, sharedLinkId } = useParams();
  const [quiz, setQuiz] = useState(null);
  const { navigate } = useNav();

  useEffect(() => {
    if (quizId && sharedLinkId) {
      getSharedQuizWithLink({ quizId, sharedLinkId }).then((data) => {
        setQuiz(data);
      });
    }
  }, [quizId, sharedLinkId]);

  if (!quiz) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth={"md"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography variant="caption" gutterBottom>
          {quiz.shared_by} shared this quiz with you
        </Typography>
        <Quiz quiz={quiz} sharedView={true} />
      </Box>
    </Container>
  );
};

export default SharedQuizPage;
