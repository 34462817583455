import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Chip,
  CircularProgress,
} from "@mui/material";
import { ExpandMore, LightbulbCircle, Article, UploadFile } from "@mui/icons-material";
import { useNav } from "contexts";
import prettyDate from "util/prettyDate";
import QuizActions from "./QuizActions";

const QuizAccordion = ({
  qgl,
  quiz,
  setQuizzes,
  setShowShareModalForQuiz,
  editingQuizId,
  setEditingQuizId,
  deletingQuiz,
  setDeletingQuiz,
}) => {
  const { navigate } = useNav();
  return (
    <Accordion key={qgl.id}>
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1} justifyContent={"start"} width={1}>
          {qgl.generated_from === "topic" && <Chip color="secondary" icon={<LightbulbCircle />} label={`Topic`} />}
          {qgl.generated_from === "wiki_article" && (
            <Chip color="secondary" icon={<Article />} label={`Wiki Article`} />
          )}
          {qgl.generated_from === "upload_pdf" && <Chip color="secondary" icon={<UploadFile />} label={`Uploaded`} />}
          {editingQuizId === quiz.id ? (
            <TextField
              defaultValue={quiz.title}
              onChange={(e) => {
                quiz.title = e.target.value;
              }}
              variant="standard"
              size="small"
              fullWidth
            />
          ) : (
            <Typography>
              <strong>{quiz.title}</strong> - {quiz.questions.length} Questions
            </Typography>
          )}

          <Box
            variant={qgl.status === "pending" ? "outlined" : "contained"}
            sx={{
              textTransform: "uppercase",
            }}
            size="small"
            marginLeft={"auto"}
          >
            {qgl.status === "pending" ? (
              <Box alignItems={"center"} justifyContent={"end"} display={"flex"} gap={1}>
                <Typography variant="caption">Generating</Typography> <CircularProgress size={20} />
              </Box>
            ) : (
              <Box alignItems={"center"} justifyContent={"end"} display={"flex"} gap={1}>
                <Typography textAlign={"right"} variant="caption">
                  Generated {prettyDate({ dateString: qgl.created_at })}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <QuizActions
          qgl={qgl}
          quiz={quiz}
          setShowShareModalForQuiz={setShowShareModalForQuiz}
          setEditingQuizId={setEditingQuizId}
          setDeletingQuiz={setDeletingQuiz}
          setQuizzes={setQuizzes}
          deletingQuiz={deletingQuiz}
          editingQuizId={editingQuizId}
        />
        {/* render quiz questions */}
        <Box display={"flex"} flexDirection={"column"} width={1} marginTop={2}>
          {quiz.questions.map((question, index) => (
            <Box key={index} display={"flex"} flexDirection={"column"} width={1} marginBottom={1}>
              {question.t === "mlt" && (
                <Typography variant="body1" boxShadow={2} padding={1} borderRadius={2} bgcolor={"background.paper"}>
                  <strong>{index + 1}. </strong>
                  {question.q}
                </Typography>
              )}
              {question.t === "fib" && (
                <Typography variant="body1" boxShadow={2} padding={1} borderRadius={2} bgcolor={"background.paper"}>
                  <strong>{index + 1}. </strong>
                  {question.q.replace(/<<\d+>>/g, "____")}
                </Typography>
              )}
              {question.t === "tf" && (
                <Typography variant="body1" boxShadow={2} padding={1} borderRadius={2} bgcolor={"background.paper"}>
                  <strong>{index + 1}. </strong>True or False: {question.q}
                </Typography>
              )}
              {question.t === "oe" && (
                <Typography variant="body1" boxShadow={2} padding={1} borderRadius={2} bgcolor={"background.paper"}>
                  <strong>{index + 1}. </strong>Open-ended: {question.q}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default QuizAccordion;
