import React from "react";

import { Box, Typography, Button, Divider } from "@mui/material";
import { notifyFailure, notifySuccess } from "toastNotifications";
import { deleteQuizShareLink } from "api/quiz";
import prettyDate from "util/prettyDate";

const QuizShareLinksList = ({ updateLinks = () => {}, quiz = null, links = [] }) => {
  if (!quiz || !links.length) {
    return;
  }
  return (
    <Box>
      <Divider />
      <Box marginBottom={2}>
        {links.map((link, index) => (
          <Box
            key={link.id}
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 1, marginTop: 2 }}
          >
            <Typography variant="body1" fontWeight={200}>
              Public link created {prettyDate({ dateString: link.created_at, short: true })},
              {link.last_accessed_at === "Never"
                ? " never accessed"
                : ` last accessed ${prettyDate({ dateString: link.last_accessed_at, short: true })}, accessed ${link.access_count} ${link.access_count === 1 ? "time" : "times"}`}
            </Typography>
            <Box display={"flex"} gap={2}>
              <Button
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(link.url);
                  notifySuccess("Link copied to clipboard");
                }}
                variant="outlined"
              >
                Copy
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  deleteQuizShareLink({
                    quizId: quiz.id,
                    shareLinkId: link.id,
                  })
                    .then(() => {
                      notifySuccess("Link deleted");
                      updateLinks({ quizId: quiz.id });
                    })
                    .catch(() => {
                      notifyFailure("Failed to delete link");
                    });
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default QuizShareLinksList;
