/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import { alpha } from "@mui/material/styles";
import { colors } from "@mui/material";

import Container from "components/Container";

const homepageItems = [
  {
    released: true,
    color: colors.lightBlue[500],
    title: "Create",
    subtitle:
      "Easily create AI-driven interactive quizzes about a) topics of your choosing, b) WikiPedia articles, or c) your own documents.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
  },
  {
    color: colors.purple[500],
    title: "Customize",
    released: true,
    subtitle:
      "Customize your quizzes by tuning the number and types of questions you want to be asked, from multiple choice and fill-in-the-blank to true/false and essay questions. You can even specify the difficulty level of the quizzes!",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
        />
      </svg>
    ),
  },
  {
    released: true,
    color: colors.green[500],
    title: "Practice",
    subtitle:
      "Practice for real test-taking environments with untimed or timed quizzes as many times as you want and receive intelligent, specific feedback on your answers.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
        />
      </svg>
    ),
  },
  {
    color: colors.red[500],
    title: "Share",
    released: true,
    subtitle:
      "Share your questions publicly and allow others to study and learn alongside you. Compete with friends and see who can score the highest.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
        />

        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
    ),
  },
  {
    color: colors.yellow[900],
    title: "Track",
    released: false,
    subtitle:
      "Track your performance on your quizzes with AI analysis and identify areas for improvement, and watch your progress over time.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
        />
      </svg>
    ),
  },
  {
    color: colors.indigo[500],
    title: "Organize",
    released: false,
    subtitle:
      "Tag your questions with one or more custom labels to organize and find them in a way that makes sense to you.",
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0 1 20.25 6v12A2.25 2.25 0 0 1 18 20.25H6A2.25 2.25 0 0 1 3.75 18V6A2.25 2.25 0 0 1 6 3.75h1.5m9 0h-9"
        />
      </svg>
    ),
  },
];

// {
//   color: colors.orange[500],
//   title: "Affordable Educational Tool",
//   subtitle:
//     "We are dedicated to making quality educational tools accessible to everyone. Our platform is priced with students and educators in mind, offering premium features at affordable rates.",
//   icon: (
//     <svg
//       height={24}
//       width={24}
//       xmlns="http://www.w3.org/2000/svg"
//       fill="none"
//       viewBox="0 0 24 24"
//       stroke="currentColor"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth={2}
//         d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//       />
//     </svg>
//   ),
// },
const FeatureCardsWithColorfullIconsAndLearnMoreLink = () => {
  return (
    <Box bgcolor={"alternate.main"}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"} marginBottom={1}>
              <Typography variant="h4" fontWeight={200}>
                why use studyrocket?
              </Typography>
            </Box>
          </Grid>

          {homepageItems.map((item, i) => (
            <Grid item xs={12} sm={6} md={4} key={i}>
              <Box
                display={"block"}
                width={1}
                height={1}
                sx={{
                  textDecoration: "none",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "translateY(-4px)",
                  },
                }}
              >
                <Box component={Card} padding={3} width={1} height={1}>
                  <Box display={"flex"} flexDirection={"column"}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      flexDirection={"row"}
                      width={"100%"}
                      alignItems={"center"}
                      variant={"rounded"}
                      marginBottom={2}
                    >
                      <Box
                        color={item.color}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={2}
                      >
                        <Box
                          component={Avatar}
                          bgcolor={alpha(item.color, 0.1)}
                          color={item.color}
                          width={40}
                          height={40}
                        >
                          {item.icon}
                        </Box>
                        <Typography variant="h6" sx={{ fontWeight: 200, textTransform: "uppercase" }}>
                          {item.title}
                        </Typography>
                      </Box>
                      {!item.released && (
                        <Typography
                          variant="caption1"
                          sx={{ marginLeft: "auto", textAlign: "end", fontWeight: 200, textTransform: "uppercase" }}
                        >
                          Coming Soon
                        </Typography>
                      )}
                    </Box>

                    <Typography color="text.secondary">{item.subtitle}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeatureCardsWithColorfullIconsAndLearnMoreLink;
