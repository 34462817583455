import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Delete, Edit, Save, Share, RocketLaunch, ReadMore, Google, Download } from "@mui/icons-material";
import { notifyFailure, notifySuccess } from "toastNotifications";
import { deleteQuiz, updateQuizTitle, getQuizzes } from "api/quiz";
import { useNav } from "contexts";

const QuizActions = ({
  qgl,
  quiz,
  setShowShareModalForQuiz,
  setEditingQuizId,
  setDeletingQuiz,
  setQuizzes,
  deletingQuiz,
  editingQuizId,
}) => {
  const { navigate } = useNav();
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"end"} marginBottom={1} gap={2}>
      {qgl.generated_from === "upload_pdf" && (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<Download />}
          onClick={() => {
            // download PDF here TODO
            notifyFailure("Feature not implemented yet");
          }}
        >
          Download PDF
        </Button>
      )}
      {qgl.generated_from === "topic" && (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          LinkComponent={"a"}
          startIcon={<Google />}
          target="_blank"
          href={`https://www.google.com/search?q=${encodeURIComponent(qgl.topic)}`}
        >
          Google this topic
        </Button>
      )}
      {qgl.generated_from === "wiki_article" && (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          LinkComponent={"a"}
          target="_blank"
          startIcon={<ReadMore />}
          href={qgl.wiki_article.url}
        >
          Visit Wikipedia Article
        </Button>
      )}
      <Button
        size="small"
        onClick={() => {
          setShowShareModalForQuiz(quiz);
        }}
        variant="contained"
        color="primary"
        startIcon={<Share />}
      >
        Share
      </Button>
      <Button
        size="small"
        onClick={() => {
          navigate(`/quiz/${quiz.id}`);
        }}
        variant="contained"
        color="primary"
        startIcon={<RocketLaunch />}
      >
        Practice
      </Button>
      {editingQuizId === quiz.id ? (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            updateQuizTitle({
              quizId: quiz.id,
              title: quiz.title,
            })
              .then(() => {
                getQuizzes().then((data) => {
                  setQuizzes(data);
                });
              })
              .then(() => {
                notifySuccess("Quiz title updated successfully");
                setEditingQuizId(null);
              })
              .catch((e) => {
                console.error(e);
                notifyFailure("Error updating quiz title");
              });
          }}
          startIcon={<Save />}
        >
          Save
        </Button>
      ) : (
        <Button
          size="small"
          disabled={editingQuizId !== null}
          onClick={() => {
            setEditingQuizId(quiz.id);
          }}
          variant="contained"
          color="tertiary"
          startIcon={<Edit />}
        >
          Edit Title
        </Button>
      )}

      <Button
        size="small"
        disabled={deletingQuiz}
        onClick={() => {
          setDeletingQuiz(true);
          deleteQuiz({ quizId: quiz.id })
            .then(() => {
              getQuizzes().then((data) => {
                setQuizzes(data);
                setDeletingQuiz(false);
                notifySuccess("Quiz deleted successfully");
              });
            })
            .catch((e) => {
              console.error(e);
              notifyFailure("Error deleting quiz");
            });
        }}
        variant="contained"
        color="secondary"
        startIcon={deletingQuiz ? <CircularProgress size={20} /> : <Delete />}
      >
        Delete
      </Button>
    </Box>
  );
};

export default QuizActions;
